.super-modal {
    padding: 0 0 0 0 !important;
}

.super-modal>.modal-dialog {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
}

.super-modal>.modal-dialog>.modal-content {
    margin: 0 0 0 0 !important;
    width: 100vw;
    height: 100vh;
}

.super-modal-movil {
    padding: 0 0 0 0 !important;
}

.super-modal-movil>.modal-dialog {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    width: 100vw;
    max-width: 100vw;
    height: fit-content;
}

.super-modal-movil>.modal-dialog>.modal-content {
    margin: 0 0 0 0 !important;
    width: 100vw;
    max-width: 100vw;
    height: fit-content;
}

.super-modal-movil>.modal-dialog>.modal-content>.modal-header>h2,
.super-modal-movil>.modal-dialog>.modal-content>.modal-header>button {
    font-size: 5rem;
}

.super-modal-movil>.modal-dialog>.modal-content>.modal-body>.row>div>.row>div>.row>div,
.super-modal-movil>.modal-dialog>.modal-content>.modal-body>.row>div>.row>div>.row>div>b,
.super-modal-movil>.modal-dialog>.modal-content>.modal-body>.row>div>.row>div>.row>div>button, 
.super-modal-movil>.modal-dialog>.modal-content>.modal-body>.row>div>.row>div>.row>div>textarea {
    font-size: 2rem;
}

.super-modal-movil>.modal-dialog>.modal-content>.modal-body>.row>div>.row>div>.row>div>h3 {
    font-size: 3rem;
}