.box {
    padding: 60px 0;
    padding: 0;
    max-height: 100%;
    width: 100%;

    @media (max-width: 991px) {
        padding: 30px 0;
    }
}

.paralax {
    overflow: hidden;
}

.c-bg-parallax {
    background-position: center center;
    background-size: cover;
}

.c-content-area {
    width: 40%;
    height: 100%;
    margin-bottom: -100%;
}
.max>.box {
    overflow: hidden;
}
.max>.c-bg-parallax {
    background-attachment: fixed;
    background-color: transparent;
    min-height: 100%;
    width: 100%;
    height: 100%;
    margin-bottom: -100%;
}
.max>.c-feature-content {
    padding: 60px 0;
    height: 100%;
    padding: 0;
    width: 40%;
    position: relative;
}

.max>.c-feature-content>.c-content-v-center {
    height: 100%;
    padding: 5rem;
}

.max>.c-feature-content>.c-content-v-center>.c-wrapper {
    display: table;
}

.max>.c-feature-content>.c-content-v-center>.c-wrapper>.c-body {
    display: table-cell;
    vertical-align: middle;
}



/* Vista en Table o celular */
.min>.c-bg-parallax {
    width: 100%;
    min-height: 350px;
}

.min>.c-content-area {
    width: 0px;
    display: none;
}

.min>.paralax>svg,
.min>.paralax>div>svg {
    display: none;
}

.min>.c-feature-content,
.min>.c-feature-content>.c-content-v-center,
.min>.c-feature-content>.c-content-v-center>.c-wrapper,
.min>.c-feature-content>.c-content-v-center>.c-wrapper>.c-body {
    height: fit-content;
    padding: .3rem;
}