.Dasboard {
    margin-top: -20px;
    padding: 2em 1rem 2rem 1rem;
    background-color: #ffffff;
    display: grid;
    grid-auto-rows: repeat(18rem);
    grid-template-areas:
        "Contadores Contadores Contadores Contadores"
        "Equilibrio Dolar Dolar Dolar"
        "Utilidad Dolar Dolar Dolar"
        "torta1 torta2 torta3 torta4"
        "ventas ventas ventas mejor"
        "ventas ventas ventas mejor";
}

.Contadores {
    width: 100%;
    grid-area: Contadores;
    display: grid;
    gap: 1rem;
    grid-auto-flow: dense;
    grid-auto-rows: 10rem;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

.center {
    display: grid;
    justify-content: center;
    grid-auto-rows: 20rem;
    align-items: center;
    justify-items: center;
    grid-template-columns: 20rem;
}

.center2 {
    display: grid;
    justify-content: center;
    grid-auto-rows: 30rem;
    align-items: center;
    justify-items: center;
    grid-template-columns: 20rem;
}

.Equilibrio {
    grid-area: Equilibrio;
}

.Utilidad {
    grid-area: Utilidad;
}

.Dolar {
    padding: 1rem 1rem 1rem 1rem;
    grid-area: Dolar;
    display: grid;
    gap: 1rem;
    grid-auto-flow: dense;
    grid-auto-rows: 70px 3fr;
    grid-template-columns: repeat(auto-fit, 100%);
}

.torta1 {
    grid-area: torta1;
}

.torta2 {
    grid-area: torta2;
}

.torta3 {
    grid-area: torta3;
}

.torta3 {
    grid-area: torta3;
}

.torta4 {
    grid-area: torta4;
}

.ventas {
    grid-area: ventas;
}

.mejor {
    grid-area: mejor;
}

g>text[text-anchor=start] {
    font-size: 15px;
}

.center>div>h5 {
    font-size: 15PX;
    display: block;
    font-weight: bold;
    text-align: center;
}

.no-data {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
}